import * as Sentry from '@sentry/react';
import type * as React from 'react';
import { type LinkProps, Link as RRLink } from 'react-router-dom';

export const Link: React.VFC<LinkProps> = ({ to, ...props }) => {
  const path = typeof to === 'string' ? convertHashPath(to) : to;

  return <RRLink to={path} {...props} />;
};

export function convertHashPath(route: string) {
  if (route.startsWith('/')) {
    return route;
  }

  let replacedRoute = route.replace(/^#/, '/');

  if (!replacedRoute.startsWith('/')) {
    replacedRoute = `/${replacedRoute}`;
  }

  if (replacedRoute !== route) {
    Sentry.withScope((scope) => {
      scope.setLevel('warning');
      scope.captureException(
        new Error(`Route ${route} was replaced with ${replacedRoute}`),
      );
    });
  }

  return replacedRoute;
}
