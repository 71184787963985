import {
  FaroErrorBoundary,
  withFaroRouterInstrumentation,
} from '@grafana/faro-react';
import {
  FlagProvider,
  useFlag,
  useFlagsStatus,
} from '@unleash/proxy-client-react';
import { configure } from 'mobx';
import * as React from 'react';
import ReactDOM from 'react-dom';
import {
  type RouteObject,
  RouterProvider,
  createBrowserRouter,
  createHashRouter,
} from 'react-router-dom';
import { TimeoutWatcher } from 'auto-converted/lib/TimeoutWatcher';
import { NoEditingContext } from 'auto-converted/views/shared/EditingContext';
import { Header } from 'components/header';
import { NavigationBlocker } from 'stores/NetworkState';
import type { Router } from 'types';

// eslint-disable-next-line no-undef
const isDevelopment = process.env.NODE_ENV === 'development';

if (isDevelopment) {
  configure({
    enforceActions: 'always',
    computedRequiresReaction: true,
    // this is annoying in that there may be _some_ code paths that don't access
    // observables, while others are. The docs even state it's not an issue to
    // overuse `observer`.
    reactionRequiresObservable: false,
    observableRequiresReaction: true,
    disableErrorBoundaries: true,
  });
}

const NothingUntilUnleashReady: React.FC<{
  children: React.ReactElement;
}> = ({ children }) => {
  const status = useFlagsStatus();

  if (status.flagsReady || status.flagsError) {
    return children;
  }

  return null;
};

const headerElement = document.querySelector('header#product-header');

export function renderReactRoot(
  children: React.ReactElement,
  selector: string,
) {
  const element = document.getElementById(selector);
  const loggedIn = window.cvpartner?.isLoggedIn ?? false;
  const unleashConfig = window.cvpartner?.unleashConfig;

  ReactDOM.render(
    <React.StrictMode>
      <FaroErrorBoundary>
        {/* this is very defensive, but better safe than sorry */}
        <NoEditingContext>
          <FlagProvider
            config={unleashConfig}
            startClient={unleashConfig != null}
          >
            <Rebranding />
            <NothingUntilUnleashReady>
              <>
                {headerElement &&
                  ReactDOM.createPortal(
                    <Header isLoggedIn={loggedIn} />,
                    headerElement,
                  )}
                {loggedIn && <TimeoutWatcher />}
                {children}
              </>
            </NothingUntilUnleashReady>
          </FlagProvider>
        </NoEditingContext>
        <NavigationBlocker />
      </FaroErrorBoundary>
    </React.StrictMode>,
    element,
  );
}

const Rebranding: React.VFC = () => {
  const rebrandingEnabled = useFlag('rebranding');

  // this is horribly ugly, but the new header is slightly taller than the old
  // one, and we have hard-coded paddings to deal with fixed positioning.
  // This also allows us to target the rebrand from our CSS, and not just JS.
  React.useEffect(() => {
    if (rebrandingEnabled) {
      document.body.classList.add('rebranding');

      return () => {
        document.body.classList.remove('rebranding');
      };
    }
  }, [rebrandingEnabled]);

  return null;
};

export function renderRouter(
  routes: RouteObject[],
  selector: string,
  iseBrowserHistoryRouter = false,
): Router {
  const opts: Parameters<typeof createHashRouter>[1] = {
    future: {
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_relativeSplatPath: true,
      v7_skipActionErrorRevalidation: true,
      // we don't use server rendering, so we don't want any hydration. This
      // prints a warning about missing fallbacks.
      v7_partialHydration: false,
    },
  };
  const router = iseBrowserHistoryRouter
    ? createBrowserRouter(routes, opts)
    : createHashRouter(routes, opts);

  const faroRouter = withFaroRouterInstrumentation(router);

  renderReactRoot(
    <RouterProvider
      router={faroRouter}
      future={{ v7_startTransition: true }}
    />,
    selector,
  );

  return faroRouter;
}
