import { observer } from 'mobx-react';
import type * as React from 'react';
import { NetworkState } from 'stores/NetworkState';
import { logo, logoIcon, logoText, spinner } from './Logo.css';

const LogoComponent: React.VFC = () => {
  const loading = NetworkState.read_or_save_in_progress;

  return (
    <a href="/home" id="logo" tabIndex={1} className={logo}>
      <span className={logoIcon({ loading })} />
      {loading && <span className={spinner} />}
      <span className={logoText} />
    </a>
  );
};

export const Logo = observer(LogoComponent);
