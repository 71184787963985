import { useFlag } from '@unleash/proxy-client-react';
import * as React from 'react';
import { CurrentUser } from 'stores/CurrentUser';
import { Header as LegacyHeader } from './legacy';
import { Header as ModernHeader } from './modern';

const { pathname } = window.location;

const isOnOtpPage = pathname === '/login/otp';
const isOnLogoutPage = pathname === '/logout';

export const Header: React.VFC<{ isLoggedIn: boolean }> = ({ isLoggedIn }) => {
  const rebrandingEnabled = useFlag('rebranding');

  React.useEffect(() => {
    if (isLoggedIn && !isOnOtpPage && !isOnLogoutPage) {
      CurrentUser.reload_if_not_loaded();
    }
  }, [isLoggedIn]);

  return rebrandingEnabled ? (
    <ModernHeader isLoggedIn={isLoggedIn} />
  ) : (
    <LegacyHeader isLoggedIn={isLoggedIn} />
  );
};
